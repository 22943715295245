import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import LogoNTT from "../assets/lazisnu-logo.svg";
import "./EmailResponse.css";
import ActivatedImage from "../assets/cuate3.png";
import SuccessImg from "../assets/konfirmasiemail.png";
import SuccessfulOrHasBeenVerified from "../assets/verifikasisukses.svg";

function SudahAktivasi() {
  return (
    <>
      <img src={LogoNTT} alt="lazisnu" className="logo-lazisnu" />
      <div className="heading-1">Email Sudah Diverifikasi</div>
      <div className="heading-2">Email anda sudah pernah diverifikasi sebelumnya. Anda dapat mengakses seluruh layanan NU CARE - LAZISNU yang telah disediakan!</div>
      <img src={SuccessfulOrHasBeenVerified} className="confirmed-image" alt="already-confirmed" />
      <div>
        {/* <a href={process.env.REACT_APP_INISA_URL}>
          <button className='button__link'>Kembali ke Health ID</button>
        </a> */}
      </div>
    </>
  );
}

function SuksesAktivasi() {
  return (
    <>
      <img src={LogoNTT} alt="inisa" className="logo-inisa" />
      <div className="heading-1">Verifikasi Email Berhasil</div>
      <div className="heading-2">Email anda berhasil terverifikasi, sekarang anda dapat mengakses seluruh layanan NU CARE - LAZISNU yang telah disediakan!</div>
      <img src={SuccessfulOrHasBeenVerified} className="confirmed-image" alt="already-confirmed" />
      <div>
        {/* <a href={process.env.REACT_APP_INISA_URL}>
          <button className='button__link'>Mulai Explore Health ID</button>
        </a> */}
      </div>
    </>
  );
}

function EmailResponse(props) {
  const [responseData, setResponseData] = useState("");
  const values = props.location.search && queryString.parse(props.location.search);

  useEffect(() => {
    const validate = async () => {
      const formdata = {
        EmailKey: values.key,
      };
      // let formdata = new FormData();
      // formdata.append("EmailKey", values.Key);
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}access/v2/email/validation-platform`, formdata, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => res)
        .catch((err) => err.response);
      if (response.data.Status === "failed") setResponseData(response?.data);
    };
    validate();
  }, [values.key]);

  return (
    <>
      {values && (
        <div className="email-response">
          <div className="wrapper">{!responseData ? <SuksesAktivasi /> : <SudahAktivasi />}</div>
        </div>
      )}
    </>
  );
}

export default EmailResponse;
