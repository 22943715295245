import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import LogoNTT from "../assets/lazisnu-logo.svg";
import "./Kwitansi.css";
import { useParams } from "react-router-dom";

function KwitansiWait() {
    return (
      <>
      <div className="centered-div">
          <center>
          <img src={LogoNTT} alt="inisa" className="logo-lazisnu" />
          <div className="heading-1">Mohon Tunggu</div>
          <div className="heading-2">Sedang mengambil data Kwitansi.</div>
          </center>
      </div>
      </>
    );
  }

function KwitansiFound({ data }) {
    useEffect(() => {
        if (data) {
          window.location.href = data;
        }
      }, [data]);

      return (
        <div className="centered-div">
            <center>
            <img src={LogoNTT} alt="inisa" className="logo-lazisnu" />
            <div className="heading-1">Mengalihkan ke Kwitansi</div>
            <div className="heading-2">Jika Anda tidak segera diarahkan,{" "}
              <a href={data}>klik disini</a>.</div>
            </center>
        </div>
      );
}

function KwitansiNotFound() {
  return (
    <>
    <div className="centered-div">
        <center>
        <img src={LogoNTT} alt="inisa" className="logo-lazisnu" />
        <div className="heading-1">Kwitansi Tidak Ditemukan</div>
        <div className="heading-2">Mohon maaf Kwitansi yang anda cari tidak dapat ditemukan</div>
        </center>
    </div>
    </>
  );
}

function Kwitansi(props) {
  const [responseData, setResponseData] = useState(null);

  // Access the :id parameter from the URL
  let { id } = useParams();

  useEffect(() => {
    const validate = async () => {
        document.title = "Kwitansi - NU CARE - LAZISNU";
      // let formdata = new FormData();
      // formdata.append("EmailKey", values.Key);
      const response = await axios
        .get(`${process.env.REACT_APP_API_LAZISNU_URL}/transaction/${id}/print`, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => res)
        .catch((err) => err.response);
        if (response?.data?.status === 200){setResponseData(response?.data?.data);}else{setResponseData("");}
    };
    validate();
  }, id);
  return (
    <>
      {responseData === null ? (
        <KwitansiWait/>
      ) : !responseData ? (
        <KwitansiNotFound />
      ) : (
        <KwitansiFound data={responseData} />
      )}
    </>
  );
}

export default Kwitansi;
